import "./modules/manage-pro";
import "./modules/commercial";
import Glide, {
    Autoplay,
    Breakpoints,
    Controls,
    Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

$(document).ready(function () {
    //SCROLL DISPLAYS
    function scrollDisplays() {
        //BACK TO TOP
        if ($(this).scrollTop() > 400) {
            $("#backtotop")
                .removeClass("opacity-0 invisible")
                .addClass("opacity-100 visible");
        } else {
            $("#backtotop")
                .removeClass("opacity-100 visible")
                .addClass("opacity-0 invisible");
        }

        //HEADER
        const header = $("#header");
        if (header.hasClass("frontpage")) {
            if ($(window).scrollTop() > 0) {
                header.addClass("menu--scrolled");
            } else {
                header.removeClass("menu--scrolled");
            }
        }
    }
    scrollDisplays();
    $(window).scroll(function () {
        scrollDisplays();
    });
    $("#backtotop").click(function () {
        $("html, body").animate(
            {
                scrollTop: "0px",
            },
            500
        );
    });

    //ANIM SECTION SCROLL
    const sections = gsap.utils.toArray('section[class*="section-"]');
    sections.forEach((section, i) => {
        const anim = gsap.fromTo(section, {autoAlpha: 0, y: -50}, {duration: 1, autoAlpha: 1, y: 0});
        ScrollTrigger.create({
            start: "top 80%",
            trigger: section,
            animation: anim,
            once: true,
        });
    });

    //NEXT SECTION
    $("#frontpage-header .next-section").click(function () {
        const top =
            $("main section:first-of-type").offset().top - $(".menu").height();
        $("html, body").animate(
            {
                scrollTop: top,
            },
            500
        );
    });

    //MENU
    const $menu = $(".menu");
    $("#menu-toggle").click(function () {
        $menu.toggleClass("menu--open");
    });
    $menu.find('#menu-header>.menu-item-has-children').click(function (){
       $(this).toggleClass('open');
       $(this).find('>.sub-menu').stop().slideToggle(300);
    });

    //SEARCH FORM
    $("#search").click(function () {
        $(this).find(".lense").toggleClass("hidden");
        $(this).find(".cross").toggleClass("hidden");

        const form = $("#desktop-searchform");
        form.toggleClass("-translate-y-full opacity-0 invisible");
        if (!form.hasClass("-translate-y-full")) {
            setTimeout(function(){
                form.find('input[type="search"]').focus();
            }, 300);
        }
    });
    $("form.searchform").submit(function (e) {
        e.preventDefault();
        const action = $(this).attr("action");
        const search = $(this).find('input[type="search"]').val();
        const isPro = localStorage.getItem("isPro") ?? "false";
        window.location.href = action + "?s=" + search + "&isPro=" + isPro;
    });

    const pageHeaderProducts = $(".page-header").find(".glide");
    if (pageHeaderProducts.length) {
        new Glide(pageHeaderProducts[0], {
            perView: 1,
            gap: 0,
            focusAt: "center",
        }).mount({ Autoplay, Breakpoints, Controls, Swipe });
    }

    // PAGE HEADER DROPDOWNS
    $(".dropdown .dropdown__label").click(function () {
        const dropdown = $(this).parent();
        dropdown.toggleClass("dropdown--open");
        dropdown.find(">.dropdown__content").stop().slideToggle(300);
    });

    // BLOC BANNIERE TRANSLATE
    const bannerBlocs = $(".section-cover");
    if (bannerBlocs) {
        bannerBlocs.each((id) => {
            let bannerBloc = $(bannerBlocs[id]);
            let nextBloc = bannerBloc.next();
            if (nextBloc.length) {
                if (
                    nextBloc.hasClass("section-actus") ||
                    nextBloc.hasClass("section-media-text-advanced")
                ) {
                    bannerBloc
                        .find(".wp-block-cover__inner-container")
                        .addClass("-translate-y-8");
                }
            }
        });
    }

    //LIGHTGALLERY WORDPRESS
    $(".wp-block-gallery")
        .lightGallery({
            download: false,
            selector: "a",
        })
        .on("onBeforeSlide.lg", function () {
            $(".lg-backdrop").css("opacity", "0.8");
            $(".lg-sub-html").css("opacity", "0");
        });

    //LANGUAGES CLICK
    $('.wpml-languages-container').click(function (){
       $(this).find('.wpml-languages').toggleClass('hidden');
    });

    //ACCORDEONS
    $(".accordeon .accordeon--label").click(function () {
        const accordeon = $(this).parent();
        accordeon.toggleClass("accordeon--open");
        accordeon.find(">.accordeon--content").stop().slideToggle(300);
    });
});
