$(document).ready(function (){
    //CHECK SESSIONSTORAGE FOR PRO REDIRECTION
    function redirect_no_professional(){
        $.post(
            ajax.url,
            {
                'action': 'not_professional',
            },
            function (response) {
                const url = JSON.parse(response);
                if(url !== window.location.href && url !== null){
                    window.location.replace(url);
                }
            }
        );
    }
    function displayModal(){
        $('body, html').addClass('overflow-hidden');
        $('#modal-pro').removeClass('hidden');
        $('#modal-pro button').click(function (){
            const isPro = $(this).data('pro');
            localStorage.setItem('isPro', isPro);
            if(isPro === false){
                redirect_no_professional();
            }

            $('body, html').removeClass('overflow-hidden');
            $('#modal-pro').addClass('hidden');
        });
    }

    //GET PARAMS URL
    function getSearchParameters() {
        let prmstr = window.location.search.substr(1);
        return prmstr != null && prmstr !== "" ? transformToAssocArray(prmstr) : {};
    }
    function transformToAssocArray( prmstr ) {
        let params = {};
        let prmarr = prmstr.split("&");
        for ( var i = 0; i < prmarr.length; i++) {
            var tmparr = prmarr[i].split("=");
            params[tmparr[0]] = tmparr[1];
        }
        return params;
    }

    //HOW TO DISPLAY MODAL
    const paramsURL = getSearchParameters();
    //IF NOT ENGLISH
    if(paramsURL.lang !== "en"){
        if(paramsURL.askPro === "true"){
            displayModal();
        }else if(!$('body').hasClass('grand-public')){
            if(!localStorage.getItem('isPro')){
                displayModal();
            }else if(localStorage.getItem('isPro') && localStorage.getItem('isPro') === "false"){
                if(!$('body').hasClass('error404')){
                    redirect_no_professional();
                }
            }
        }
    }
});